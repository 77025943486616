import { useCallback } from "react";
import { Box, FormControl, Grid, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import TextField from "../TextField";
import { renderFormField } from "../../helpers";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { FieldConditionalSubFormProps } from "../../helpers/types";
import FieldTooltip from "../FieldTooltip";
import styled from "styled-components";

export const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 5px;
`;

interface Props extends FieldConditionalSubFormProps {
  helpers?: string[];
}

export default function ConditionalFormMultidropdown(props: Props) {
  const handleChange = useCallback(
    (value: string | null, subFieldId?: string) => {
      if (props.onChange) {
        if (subFieldId) {
          props.onChange({
            ...props.value,
            data: {
              ...props.value.data,
              fields: {
                ...props.value.data.fields,
                [subFieldId]: value,
              },
            },
          });
        }

        if (!subFieldId) {
          props.onChange({
            ...props.value,
            value,
          });
        }
      }
    },
    [props]
  );

  const clearAllSubfields = (value: string) => {
    if (!props.fields) return;
    const subFieldsValues = props.fields.reduce((acc, field) => {
      return {
        ...acc,
        [field.id]: null,
      };
    }, {});

    if (props.onChange) {
      props.onChange({
        value,
        data: {
          fields: { ...subFieldsValues },
        },
      });
    }
  };
  const getSubFieldValueById = useCallback(
    (subFieldId: string) => {
      const value = props?.value?.data?.fields[subFieldId];
      return value === null ? "" : Array.isArray(value) ? value[0] : value;
    },
    [props.value]
  );

  const getErrorField = (fieldId: string) => {
    try {
      return props?.error?.data?.fields[fieldId];
    } catch (error) {
      return "";
    }
  };

  const options =
    props?.options &&
    props?.options.map((option, index) => {
      return { label: option, helper: props?.helpers && props?.helpers[index] };
    });

  return (
    <div>
      <FormControl
        variant="outlined"
        fullWidth
        disabled={props.disabled}
        error={!!props.error}
      >
        <Autocomplete
          id="autocomplete-dropdown"
          size="small"
          autoHighlight
          clearOnEscape={true}
          disabled={props.disabled}
          options={options as { label: string; helper?: string }[]}
          getOptionLabel={(option) => option.label}
          renderOption={(option) => (
            <MenuItem>
              {option.label}
              {option.helper && (
                <Typography
                  variant="caption"
                  style={{ color: Tokens.neutralColor.medium[400] }}
                >
                  {option.helper}
                </Typography>
              )}
            </MenuItem>
          )}
          renderInput={(params) => (
            <Box display="flex" alignItems="center">
              <TextField
                error={!!props.error}
                variant="outlined"
                {...params}
                label={props.label}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: true,
                }}
              />
              {props.id === "GENDER" && (
                <FieldTooltip
                  message="Se você tiver preocupações com esta pergunta ou precisar de
                    mais informações, sinta-se à vontade para entrar em contato
                    com a Caju."
                />
              )}
            </Box>
          )}
          value={{ label: props?.value?.value ?? "" }}
          onChange={(_: any, newValue, reason) => {
            if (reason === "clear") {
              handleChange(null);
              return;
            }
            if (newValue?.label) {
              if (!props.showForOptionValues?.includes(newValue.label)) {
                clearAllSubfields(newValue.label);
              } else {
                handleChange(newValue.label);
              }
            }
          }}
          noOptionsText="Nenhuma opção encontrada…"
        />
      </FormControl>
      <Grid
        container
        direction="column"
        spacing={2}
        style={{ marginTop: 8, marginBottom: 1 }}
      >
        {props.fields?.map((field) => {
          const subFieldValue = getSubFieldValueById(field.id);
          const enableFields = props.showForOptionValues?.includes(
            props?.value?.value ?? ""
          );
          const errorField = getErrorField(field.id);
          return (
            enableFields && (
              <Grid item xs={12} key={field.id}>
                {renderFormField(field.type, {
                  required: field.required,
                  label: field.label,
                  id: `${props.id}.data.fields.${field.id}`,
                  disabled: !enableFields,
                  fields: props.fields,
                  onChange: (value: any) => {
                    handleChange(value, field.id);
                  },
                  value: subFieldValue,
                  isMobile: props.isMobile,
                  options: field.data?.options,
                  error: errorField,
                  data: field?.data,
                }, field.id, null)}
              </Grid>
            )
          );
        })}
      </Grid>
    </div>
  );
}
